import React from 'react';
import { Link } from 'react-router-dom';

import img from '../../assets/img/courses/main-home/1.jpg';
import formatDate from '../../components/utils/FormatDate';

const contest = {
    "contest_created": "Fri, 26 Jul 2024 07:00:00 GMT",
    "contest_start": "Fri, 19 Jul 2024 00:00:00 GMT",
    "cover_photo": "https://cdn.vasha.com.bd/file?filename=wallpaperflare.com_wallpaper_6_1720837975835.jpg",
    "created_by": "dip.pal.513@gmail.com",
    "description": "description_6_1720837976038.html",
    "fee": 45.0,
    "id": 17,
    "kaggle_url": "kaggle.com",
    "max": 3,
    "min": 2,
    "onsite": "Fri, 26 Jul 2024 00:00:00 GMT",
    "reg_deadline": "Tue, 23 Jul 2024 00:00:00 GMT",
    "submission_deadline": "Wed, 17 Jul 2024 00:00:00 GMT",
    "title": "AI ML"
}

const Competition = (props) => {
    const [contest, setContest] = React.useState(null);
    const [color, setColor] = React.useState(null);

    React.useEffect(() => {
        setContest(props.contest);
        if (props.status === 'running') setColor('green');
        else if (props.status === 'upcoming') setColor('yellow');
        else setColor('red');
    }, [props]);

    return (
        <div className={props.itemClass}>
            <div className="courses-grid" style={{ height: '55vh' }}>
                <div className="img-part">
                    <Link to={props.contestLink}>
                        <img
                            src={contest ? contest.cover_photo : img}
                            alt={contest ? contest.title : 'Contest'}
                            style={{
                                objectFit: 'cover',
                                width: '100%',
                                height: '40vh'
                            }}
                        />
                    </Link>
                </div>
                <div className="row" style={{ display: 'flex' }}>
                    <h3 className="title m-2">
                        <Link to={props.contestLink}>{contest?.title}</Link>
                        <span
                            style={{
                                fontSize: '1rem',
                                padding: '0.5rem',
                                marginLeft: '1rem'
                            }}
                        >
                            <mark >{props?.status.toUpperCase()}</mark>
                        </span>
                    </h3>
                    {/**
                     * In the following div, we will show the following:
                     * Start Date
                     * End Date
                     * Registration Deadline
                     * View Contest Button
                     */}
                    <div className="blog-meta m-1" style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div align='left ml-5'>
                            <ul className="btm-cate">
                                {/**Start Date */}
                                <li>
                                    <div className="blog-date">
                                        <i className="fa fa-calendar-check-o"></i> Start: {formatDate(contest?.contest_start)}
                                    </div>
                                </li>
                                {/**Registration Deadline */}
                                <li>
                                    <div className="author">
                                        <i className="fa fa-calendar-check-o"></i> Onsite: {formatDate(contest?.reg_deadline)}
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className="btn-part" align="right"
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                alignItems: 'center',
                                padding: '0.5rem',
                                borderRadius: '0.5rem',
                                backgroundColor: '#21a7d0',
                                color: 'white',
                                marginRight: '1rem'
                            }}
                        >
                            {
                                contest && 
                                <Link to={"/contest?id=" + contest.id} style={{color:'white'}}>
                                    View <i className="flaticon-right-arrow"></i>
                                </Link>
                            }
                        </div>
                    </div>
                </div>



            </div>
        </div>
    )
}

export default Competition;